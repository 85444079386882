import getEnvVars from '../../env';
import { V1_LOAD_ALERT, V1_LOAD_ALERT_ORDER, V1_LOAD_BEST_AVAILABLE, V1_LOAD_H2H_REQUESTS, V1_LOAD_LATEST_TRADES, V1_LOAD_ORDER_STATS, V1_LOAD_PLAYER_COUPONS, V1_LOAD_PLAYER_HEDGES, V1_LOAD_VIEWING_REFERRAL, V1_UPDATE_APP_ALERT, V1_UPDATE_COMPETITION_PLAYERS, V1_UPDATE_COMPETITION_RECORDS, V1_UPDATE_EVENT, V1_UPDATE_GROUP_MESSAGE, V1_UPDATE_LATEST_TRADE, V1_UPDATE_LIVE_FEED, V1_UPDATE_MATCH, V1_UPDATE_ME, V1_UPDATE_ORDER, V1_UPDATE_PLAYER_BALANCE, V1_UPDATE_PLAYER_NOTIFICATION, V1_UPDATE_PLAYER_ORDER, V1_UPDATE_POLLS, V1_UPDATE_POLL_CAMPAIGNS, V1_UPDATE_POLL_OPTIONS, V1_UPDATE_POLL_SUMMARIES, V1_UPDATE_POST, V1_UPDATE_POST_COMMENT_STAT, V1_UPDATE_POST_REACTION_STATS, V1_UPDATE_SOCKET_CONNECTED, V1_UPDATE_SQUARES_COMPETITION, V1_UPDATE_SQUARE_DETAILS, V1_UPDATE_TOURNAMENT, V1_UPDATE_TRADE } from './types';
import AsyncStorage from '@react-native-async-storage/async-storage';
import store from '../../store';
import { social_clearGroup } from './social';
import { isSafari, isDesktop } from 'react-device-detect';
import { PlayerNotificationProps } from '../types/auth_svc';
import { DEPOSIT_STATUS } from '../actions/types';
import { formatBestAvailable, market_getBestAvailable } from './markets';
import { getPlayerBalanceByPlayerId } from './player';
import { auth_getAppAlerts } from './auth';
import { EventOrderStatProps, TradeProps } from '../types/mk_svc';
const { WS_SVC_API, APP_VERSION } = getEnvVars()

let ws:WebSocket | undefined = undefined//new WebSocket(WS_SVC_API) 
let reconnect_attempter:any = undefined
let try_reconnect = true


document.addEventListener('visibilitychange', () => {
    if(document.visibilityState === 'hidden'){
        if(ws){
            try_reconnect = false;
            if(reconnect_attempter || reconnect_attempter == 0){ 
                clearInterval(reconnect_attempter) 
            }
            ws.close();
        }
    }
    if(document.visibilityState === 'visible'){
        try_reconnect = true
        socket_connect() 
    }
});

export const checkSocketConnection = async() => {
    if(ws?.readyState != 1){ return store.dispatch({ type:V1_UPDATE_SOCKET_CONNECTED, connected: false }) }
    else {
        return store.dispatch({ type:V1_UPDATE_SOCKET_CONNECTED, connected: true })
    }
}

export const socket_connect = (init?:boolean) => {
    if(ws){
        ws.send(JSON.stringify({ type: 'PING', data: new Date().toTimeString() }))
    } else {
        ws = new WebSocket(WS_SVC_API)
    }

    ws.onopen = async() => {
        //if(init_connect){ init_connect = false }
        if(ws){ ws.send(JSON.stringify({ type: 'PING', data: new Date().toTimeString() }))}
        store.dispatch({ type: V1_UPDATE_SOCKET_CONNECTED, connected: true })
        if(reconnect_attempter){ 
            reconnect_attempter = clearInterval(reconnect_attempter) 
        }
        
        try {
            let { access_token } = store.getState().util.auth_tokens
            let { distinct_id } = store.getState().util.analytic_tokens 
            if(access_token && distinct_id){ 
                socket_authenticate(access_token, distinct_id)
            }
        } catch (error) {
            console.log('failed to attempt authentication')
        }


        let player_id = store.getState().player.player?.player_id
        if(!init){
            store.dispatch(market_getBestAvailable())
            store.dispatch(auth_getAppAlerts())
            if(store.getState().auth.isAuthenticated && player_id){
                store.dispatch(getPlayerBalanceByPlayerId(player_id))
            }
        }
    }

    ws.onmessage = (body) => {
        var message = JSON.parse(body.data);
        store.dispatch(socket_handleMessage(message))
    }

    ws.onclose = () => {
        store.dispatch({ type:V1_UPDATE_SOCKET_CONNECTED, connected: false })
        ws = undefined
        if(try_reconnect){
            socket_attemptReconnect()
        }
    }
    ws.onerror = (e) => {
        ws = undefined

        //console.log(`error`)
        //console.log(reconnect_attempts)
        //console.log(reconnect_attempter)

        //if(reconnect_attempts > 2 && reconnect_attempter){
        //    console.log('clearing interval!!')
        //    reconnect_attempter = clearInterval(reconnect_attempter)
        //    console.log(reconnect_attempter)
        //}
    }
}

export const socket_handleMessage = (message:any) => {
    return (dispatch:any, getState:any) => {
        switch(message.type){
            case 'APP_VERSION':
                return checkAppVersion(message.app_version)
            case 'PONG':
                return setTimeout(() => { ws?.send(JSON.stringify({ type: 'PING', data: new Date().toTimeString() })) }, 10000); 
            case V1_UPDATE_ME:
                return dispatch({ type: V1_UPDATE_ME, player:message.data })
            case V1_UPDATE_PLAYER_BALANCE:
                return dispatch({ type:V1_UPDATE_PLAYER_BALANCE, player_balance: message.data })
            case V1_UPDATE_PLAYER_ORDER:
                if(message.fulfill_alert){ 
                    dispatch({ type: V1_LOAD_ALERT_ORDER, order: message.data }) 
                }
                return dispatch({ type:V1_UPDATE_PLAYER_ORDER, order: message.data })
            case V1_UPDATE_ORDER:
                return dispatch({ type:V1_UPDATE_ORDER, order: message.data })
            case V1_UPDATE_POST_REACTION_STATS:
                return dispatch({ type: V1_UPDATE_POST_REACTION_STATS, post_reaction_stats: message.data  })
            case V1_UPDATE_POST_COMMENT_STAT:
                return dispatch({ type: V1_UPDATE_POST_COMMENT_STAT, post_comment_stat: message.data  })
            case V1_UPDATE_EVENT:
                return dispatch({ type: V1_UPDATE_EVENT, events: { [message.data.event_id]: message.data } })
            case V1_UPDATE_TOURNAMENT:
                return dispatch({ type: V1_UPDATE_TOURNAMENT, tournaments: { [message.tournament.tournament_id] : message.tournament } })
            case V1_UPDATE_MATCH:
                return dispatch({ type: V1_UPDATE_MATCH, matches: { [message.match.match_id] : message.match } })
            case V1_UPDATE_LATEST_TRADE:
                let unique_event_ids = [ ...new Set(message.data.map((d:TradeProps) => `${d.event_id}:${d.event_type}`))]
                unique_event_ids.map(id => {
                    let split_id = id.split(':')
                    let trades = message.data.filter((d:TradeProps) => d.event_id == split_id[0] && d.event_type == split_id[1])
                    dispatch({ type: V1_UPDATE_LATEST_TRADE, latest_trades: trades })
                })   
                dispatch({ type: V1_UPDATE_TRADE, trade: message.data[message.trade_side_index??0] })
                return
            case V1_UPDATE_PLAYER_NOTIFICATION:
                let pn:PlayerNotificationProps = message.data
                if(!pn){ return }
                dispatch({ type: V1_UPDATE_PLAYER_NOTIFICATION, player_notification:pn })
                dispatch({ type: V1_LOAD_ALERT, alert: { title: pn.title , type:'alert', body: pn.body } })
                return
            case V1_LOAD_BEST_AVAILABLE:
                const { best_available, order_stats, latest_trades } = formatBestAvailable(message.data)
                dispatch({ type: V1_LOAD_BEST_AVAILABLE, best_available })
                dispatch({ type: V1_LOAD_ORDER_STATS, order_stats })
                dispatch({ type: V1_LOAD_LATEST_TRADES, latest_trades })
                
            case V1_LOAD_PLAYER_COUPONS:
                return dispatch({ type: V1_LOAD_PLAYER_COUPONS, player_coupons: [message.data] })
            case V1_UPDATE_GROUP_MESSAGE:
                return dispatch({ type: V1_UPDATE_GROUP_MESSAGE, group_message: message.data })
            case V1_LOAD_H2H_REQUESTS:
                return dispatch({ type:V1_LOAD_H2H_REQUESTS, orders: message.data })
            case V1_UPDATE_POST:
                return dispatch({ type: V1_UPDATE_POST, post: message.data })
            case V1_LOAD_VIEWING_REFERRAL:
                const { promo, player_referral, referrer, code_request } = message.data;
                return dispatch({ type: V1_LOAD_VIEWING_REFERRAL, promo, player_referral, referrer, code_request })
            case V1_UPDATE_APP_ALERT:
                return dispatch({ type: V1_UPDATE_APP_ALERT, app_alert:message.data })
            case DEPOSIT_STATUS:
                return dispatch({ type: DEPOSIT_STATUS, status:message })
            case V1_UPDATE_COMPETITION_RECORDS:
                return dispatch({ type: V1_UPDATE_COMPETITION_RECORDS, competition_records: message.data })
            case V1_UPDATE_COMPETITION_PLAYERS:
                return dispatch({ type: V1_UPDATE_COMPETITION_PLAYERS, competition_players: message.data })
            case V1_UPDATE_LIVE_FEED:
                return dispatch({ type:V1_UPDATE_LIVE_FEED, article_feed: message.data })
            case V1_LOAD_PLAYER_HEDGES:
                return dispatch({ type: V1_LOAD_PLAYER_HEDGES, hedges: message.data, orders: [], offset: 1 })
            case V1_UPDATE_POLL_OPTIONS:
                return dispatch({ type:V1_UPDATE_POLL_OPTIONS, poll_options: message.poll_options })
            case V1_UPDATE_POLL_SUMMARIES:
                return dispatch({ type:V1_UPDATE_POLL_SUMMARIES, poll_summaries: message.poll_summaries })
            case V1_UPDATE_POLLS:
                return dispatch({ type:V1_UPDATE_POLLS, polls: message.polls })
            case V1_UPDATE_POLL_CAMPAIGNS:
                return dispatch({ type:V1_UPDATE_POLL_CAMPAIGNS, poll_campaigns: message.poll_campaigns })
            case 'PONG':
                return
            default: return
        }
    }
}

const dictionizeArray = (objects_array:any[], primary_key:string) => {
    return objects_array.reduce((acc:{ [key: string] : any }, obj) => {
        acc[obj[primary_key]] = obj;
        return acc;
    }, {});
}

export const socket_authenticate = (access_token:string, device_id:string) => {
    if(!ws || ws.readyState !== 1){ return socket_connect() }
    ws.send(JSON.stringify({
        type: 'AUTHENTICATE',
        access_token,
        device_id
    }))
}

export const socket_joinChat = (group_id:string) => {
    return async(dispatch:any, getState:any) => {
        if(!ws || ws.readyState !== 1){ return  }
        const device_id = await AsyncStorage.getItem(`dvcid`)
        const access_token = getState().auth.access_token
        ws.send(JSON.stringify({
            type: 'JOIN_CHAT',
            access_token,
            device_id,
            group_id
        }))
    }
}

export const socket_leaveChat = () => {
    return async(dispatch:any, getState:any) => {
        if(!ws || ws.readyState !== 1){ return  }
        const device_id = await AsyncStorage.getItem(`dvcid`)
        const access_token = getState().auth.access_token
        const group = getState().social.group
        if(!group){ return }

        ws.send(JSON.stringify({
            type: 'LEAVE_CHAT',
            access_token,
            device_id,
            group_id: group.group_id
        }))
        dispatch(social_clearGroup())
    }
}


const socket_attemptReconnect = () => {
    reconnect_attempter = setInterval(() => {
        socket_connect()
    },1500)
}

const checkAppVersion = async(app_version:string) => {
    //Get the current version of the app from server and compare to stored version
    if(app_version !== APP_VERSION){ return unregisterServiceWorkerCache() }
  }
  
  const unregisterServiceWorkerCache = async() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations().then(function(registrations) {
        registrations.map(r => {
          if(isSafari){ r.unregister() }
          else { 
            r.update(); r.unregister(); }
          if (caches) {
            // Service worker cache should be cleared with caches.delete()
            caches.keys().then(async (names) => {
              await Promise.all(names.map(name => caches.delete(name)));
            });
          }
        })
      })
      return hardReload()
    } else {
      return hardReload()
    }
  }

  const hardReload = async() => {
    const reloads = await AsyncStorage.getItem('reloads')
    if(!reloads){ 
      await AsyncStorage.setItem('reloads', '1')
      return window.location.reload()
    }
    let reloadInt = parseInt(reloads)
    if(reloadInt > 2){ return await AsyncStorage.removeItem('reloads')}
    await AsyncStorage.setItem('reloads', (reloadInt + 1).toString())
    return window.location.reload()
  }

socket_connect(true)