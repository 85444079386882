import axios from "axios"
import getEnvVars from '../../env';
import { navigate } from "../../navigation/RootNavigation";
import { errHandler } from "../errHandler";
import { OrderProps } from "../types/mk_svc";
import { ShareProps } from "../types/soc_svc";
import { BracketCompetitionProps, BracketCompetitionScoringRuleProps, CompetitionMatchProps, CompetitionPlayerBracketProps, CompetitionPlayerProps, CompetitionProps, CompetitionSeasonProps, CreateCompetitionProps, PlayerBracketPickProps, PlayerBracketProps, PlayerPickProps, PlayerSquareProps, SquareOfferProps } from "../types/tp_svc";
import { event_getBulkEvents } from "./events";
import { V1_LOAD_ALERT, V1_LOAD_COMPETITION_OPTIONS, V1_LOAD_PLAYER_BRACKETS, V1_LOAD_SQUARES_COMPETITIONS, V1_LOAD_SQUARE_DETAILS, V1_UPDATE_BRACKET_COMPETITIONS, V1_UPDATE_BRACKET_COMPETITION_SCORING_RULES, V1_UPDATE_COMPETITIONS, V1_UPDATE_COMPETITION_MATCHES, V1_UPDATE_COMPETITION_MATCH_MARKETS, V1_UPDATE_COMPETITION_PLAYERS, V1_UPDATE_COMPETITION_PLAYER_BRACKETS, V1_UPDATE_COMPETITION_RECORDS, V1_UPDATE_COMPETITION_RESULTS, V1_UPDATE_COMPETITION_SEASONS, V1_UPDATE_DRAFT_COMPETITION, V1_UPDATE_DRAFT_WAGER_ORDER, V1_UPDATE_INVITED_COMPETITION_PLAYERS, V1_UPDATE_MY_PLAYER_PICKS, V1_UPDATE_PLAYER_BRACKET_PICKS, V1_UPDATE_PLAYER_PICKS, V1_UPDATE_SEASON_LEADERS } from "./types";
import { RoundEventProps } from "../types/sr_svc";
import { player_getBulkPlayers } from "./player";
const { TP_SVC_API } = getEnvVars();

export const comps_getCompetitionOptions = () => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.get(`${TP_SVC_API}/v1/competitions/options`)
            const { competition_result_types, competition_types } = resp.data
            dispatch({ type:V1_LOAD_COMPETITION_OPTIONS, competition_result_types, competition_types})
        } catch (e) {
            console.log(e)
        }
    }
}

export const comps_getCompetitions = (offset:number, status:string) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.get(`${TP_SVC_API}/v1/competitions/all?status=${status}&offset=${offset}`)
            dispatch({ type: V1_UPDATE_COMPETITIONS, competitions: resp.data.competitions })
        } catch (e) {
            console.log(e)
        }
    }
}

export const comps_getCompetitionSeasonsByAdminId = (status?:string) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.get(`${TP_SVC_API}/v1/competitions/seasons/me?status=${status}`)
            dispatch({ type:V1_UPDATE_COMPETITION_SEASONS, competition_seasons:resp.data.competition_seasons })
        } catch (e) {
            console.log(e)
        }
    }
}

export const comps_getEnteredSeasons = () => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.get(`${TP_SVC_API}/v1/competitions/seasons/entered`)
            dispatch({ type:V1_UPDATE_COMPETITION_SEASONS, competition_seasons: resp.data.competition_seasons })
        } catch (e) {
            console.log(e)
        }
    }
}

export const compsapi_getCompetitionSeasonTemplates = async(league_id?:string):Promise<CompetitionSeasonProps[]> => {
    try {
        const resp = await axios.get(`${TP_SVC_API}/v1/competitions/seasons/templates?league_id=${league_id}`)
        return resp.data.competition_seasons
    } catch (e) {
        console.log(e)
        return []
    }
}

export const compsapi_createCompetitionSeason = async(competition_season:CompetitionSeasonProps):Promise<CompetitionSeasonProps|undefined> => {
        try {
            const resp = await axios.post(`${TP_SVC_API}/v1/competitions/season/create`, { competition_season })
            return resp.data.competition_season
        } catch (e) {
            return undefined
        }
}


export const compsapi_getCompetitionsBySeasonId = async(competition_season_id:string):Promise<CompetitionProps[]> => {
    const resp = await axios.get(`${TP_SVC_API}/v1/competitions/season/${competition_season_id}`)
    return resp.data.competitions
}

export const comps_getLeaderboardBySeasonId = (competition_season_id:string)=> {
    return async(dispatch:any) => {
        const resp = await axios.get(`${TP_SVC_API}/v1/competitions/leaderboard/season/${competition_season_id}`)
        let player_ids = resp.data.season_leaderboard.map((l:any) => l.player_id)
        if(player_ids.length > 0){ dispatch(player_getBulkPlayers(player_ids)) }
        dispatch({ type:V1_UPDATE_SEASON_LEADERS, season_leaders: resp.data.season_leaderboard, competition_season_results: resp.data.competition_season_results })
    }   
}

export const comps_getSeasonById = (competition_season_id:string) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.get(`${TP_SVC_API}/v1/competitions/seasons/season/${competition_season_id}`)
            dispatch({ type:V1_UPDATE_COMPETITION_SEASONS, competition_seasons: [resp.data.competition_season] })
        } catch (e) {
            console.log(e)
        }
    }
}

export const comps_setDraftWagerOrder = (order?:OrderProps) => {
    return async(dispatch:any) => {
        try {
            dispatch({ type: V1_UPDATE_DRAFT_WAGER_ORDER, order })
        } catch (e) {
            console.log(e)
        }
    }
}

export const comps_getInvitedCompetitions = () => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.get(`${TP_SVC_API}/v1/competitions/invited`)
            dispatch({ type: V1_UPDATE_COMPETITIONS, competitions: resp.data.competitions })
            dispatch({ type: V1_UPDATE_INVITED_COMPETITION_PLAYERS, competition_players: resp.data.competition_players })
        } catch (e) {
            console.log(e)
        }
    }
}

export const comps_getEnteredCompetitions = () => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.get(`${TP_SVC_API}/v1/competitions/entered`)
            dispatch({ type: V1_UPDATE_COMPETITIONS, competitions: resp.data.competitions })
        } catch (e) {
            console.log(e)
        }
    }
}

export const comps_getCompetitionHistoryByRange = (player_id:string, range:number) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.get(`${TP_SVC_API}/v1/competitions/history/player/${player_id}?range=${range}`)
            dispatch({ type: V1_UPDATE_COMPETITIONS, competitions: resp.data.competitions })
            dispatch({ type: V1_UPDATE_COMPETITION_PLAYERS, competition_players: resp.data.competition_players })
        } catch (e) {
            console.log(e)
        }
    }
}

export const comps_getCompetitionsBySponsorId = (sponsor_id:string) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.get(`${TP_SVC_API}/v1/competitions/sponsored/${sponsor_id}`)
            dispatch({ type: V1_UPDATE_COMPETITIONS, competitions: resp.data.competitions })
        } catch (e) {
            console.log(e)
        }
    }
}

export const comps_rejectCompetitionInvite = (competition_player:CompetitionPlayerProps) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.post(`${TP_SVC_API}/v1/competitions/player/reject`, { competition_player })
            dispatch({ type: V1_UPDATE_INVITED_COMPETITION_PLAYERS, competition_players: [resp.data.competition_player]})
        } catch (e) {
            console.log(e)
        }
    }
}

export const comps_setDraftCompetition = (draft_competition:CreateCompetitionProps) => {
    return (dispatch:any) => {
        dispatch({ type: V1_UPDATE_DRAFT_COMPETITION, draft_competition })
    }
}

export const comps_getCompetitionById = (competition_id:string) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.get(`${TP_SVC_API}/v1/competitions/competition/${competition_id}`)
            dispatch({ type:V1_UPDATE_COMPETITIONS, competitions:[resp.data.competition] })
        } catch (e) {
            console.log(e)
        }
    }
}


export const comps_getCompetitionById2 = async(competition_id:string) => {
    try {
        const resp = await axios.get(`${TP_SVC_API}/v1/competitions/competition/${competition_id}`)
        return resp.data.competition
    } catch (e) {
        console.log(e)
    }
}

export const comps_getCompetitionSeasonById = async(competition_season_id:string) => {
    try {
        const resp = await axios.get(`${TP_SVC_API}/v2/competitions/seasons/season/${competition_season_id}`)
        return resp.data.competition_season
    } catch (e) {
        console.log(e)
    }
}

export const sq_getSquaresCompetitionById = async(sq_comp_id:string) => {
    try {
        const resp = await axios.get(`${TP_SVC_API}/tp/squares/comp/details/${sq_comp_id}`)
        return resp.data.squares_competition
    } catch (e) {
        console.log(e)
    }
}

export const comps_getCompetitionByIdForEdit = (competition_id:string) => {
    return async(dispatch:any) => {
        try {
            const cresp = await axios.get(`${TP_SVC_API}/v1/competitions/competition/${competition_id}`)
            const cmresp = await axios.get(`${TP_SVC_API}/v1/competitions/matches/${competition_id}?status=inprogress`)
            let market_ids:string[] = [ ...new Set(cmresp.data.competition_match_markets.map(cmm => cmm.market_id)) ]
            
            let draft_competition:CreateCompetitionProps = {
                competition: { ...cresp.data.competition, market_ids },
                competition_matches: cmresp.data.competition_matches,
                competition_match_markets: cmresp.data.competition_match_markets,
                updated: 1,
                additional_markets: [],
                competition_players: []
            }
            dispatch({ type: V1_UPDATE_DRAFT_COMPETITION, draft_competition })
        } catch (e) {
            console.log(e)
        }
    }
}

export const comps_getCompetitionByCode = (competition_code:string, navigation?:any) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.get(`${TP_SVC_API}/v1/competitions/code/${competition_code}`)
            if(!navigation){ return }
            if(resp.data.type == 'competition'){
                dispatch({ type:V1_UPDATE_COMPETITIONS, competitions:[resp.data.competition] })
                navigation.navigate('CompetitionStack', { screen:'CompetitionPlay', initial:false, params: { competition_id: resp.data.competition.competition_id } })
                return
            }
            if(resp.data.type == 'bracket_competition'){
                navigation.navigate('CompetitionStack', { screen: 'BracketPlay', initial:false, params: { bracket_competition_id: resp.data.competition.bracket_competition_id } })
            }

        } catch (e) {
            console.log(e)
            dispatch({ type: V1_LOAD_ALERT, alert: { title:'Error', type:'error', body:'Unable to find competition' } })

        }
    }
}

export const comps_getCompetitionResultsByCompetitionId = (competition_id:string, offset:number, player_id?:string) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.get(`${TP_SVC_API}/v1/results/competition/${competition_id}?offset=${offset}&player_id=${player_id}`)  
            dispatch({ type: V1_UPDATE_COMPETITION_RESULTS, offset, competition_results:resp.data.competition_results })
            dispatch({ type: V1_UPDATE_COMPETITION_RECORDS, offset, competition_records:resp.data.competition_records })
        } catch (e) {
            console.log(e)
        }
    }
}

export const comps_getPlayerPicksByCompetitionAndPlayerId = (competition_id:string, player_id:string, offset:number, me?:boolean) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.get(`${TP_SVC_API}/v1/picks/competition/${competition_id}?player_id=${player_id}&offset=${offset}`)
            if(me){
                return dispatch({ type: V1_UPDATE_MY_PLAYER_PICKS, player_picks:resp.data.player_picks, offset })
            }
            return dispatch({ type: V1_UPDATE_PLAYER_PICKS, player_picks:resp.data.player_picks, offset })
        } catch (e) {
            console.log(e)
        }
    }
}

export const comps_getCompetitionMatchesByCompetitionId = (competition_id:string, status:string, offset:number) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.get(`${TP_SVC_API}/v1/competitions/matches/${competition_id}?status=${status}`)
            let needed_events:string[] = []
            resp.data.competition_matches.map((m:CompetitionMatchProps) => {
                if(m.event_type === 'team'){ needed_events.push(m.event_id) }
            })
            if(needed_events.length > 0){ 
                dispatch(event_getBulkEvents(needed_events)) 
            }
            dispatch({ type: V1_UPDATE_COMPETITION_MATCHES, competition_matches:resp.data.competition_matches })
            dispatch({ type: V1_UPDATE_COMPETITION_MATCH_MARKETS, competition_match_markets:resp.data.competition_match_markets })
        } catch (e) {
            console.log(e)
        }
    }
}

export const comps_getCompetitionPlayersByCompetitionId = (competition_id:string, status:string, offset:number) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.get(`${TP_SVC_API}/v1/competitions/players/${competition_id}?status=${status}`)
            dispatch({ type: V1_UPDATE_COMPETITION_PLAYERS, competition_players:resp.data.competition_players, offset })
        } catch (e) {
            console.log(e)
        }
    }
}


export const comps_createCompetition = (draft_competition:CreateCompetitionProps) => {
    return async(dispatch:any, getState:any) => {
        try {
            const resp = await axios.post(`${TP_SVC_API}/v1/competitions/competition/create`, { draft_competition })
            const { competition } = resp.data;
            let old_draft_competition = getState().comp.draft_competition
            dispatch({ type: V1_UPDATE_COMPETITIONS, competitions:[competition], alert:true })
            dispatch({ type: V1_UPDATE_DRAFT_COMPETITION, draft_competition: { ...old_draft_competition, competition } })
        } catch (e) {
            console.log(e)
            errHandler(e)
        }
    }
}

export const comps_editCompetition = (draft_competition:CreateCompetitionProps) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.post(`${TP_SVC_API}/v1/competitions/competition/edit`, { draft_competition })
            const { competition, competition_matches, competition_match_markets } = resp.data
            dispatch({ type:V1_UPDATE_COMPETITIONS, competitions: [competition] })
            dispatch({ type: V1_UPDATE_COMPETITION_MATCHES, competition_matches: competition_matches })
            dispatch({ type: V1_UPDATE_COMPETITION_MATCH_MARKETS, competition_match_markets: competition_match_markets })
            navigate('CompetitionPlay', { competition_id: competition.competition_id })
        } catch (e) {
            console.log(e)
            errHandler(e)
        }
    }
}

export const comps_createPlayerPick = (player_pick:PlayerPickProps) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.post(`${TP_SVC_API}/v1/picks/pick/create`, { player_pick })
            dispatch({ type: V1_UPDATE_MY_PLAYER_PICKS, player_picks: [resp.data.player_pick], offset:1 })
        } catch (e) {
            console.log(e)
            errHandler(e)
        }
    }
}

export const comps_removePlayerPick = (player_pick_id:string) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.post(`${TP_SVC_API}/v1/picks/pick/remove`, { player_pick_id })
            dispatch({ type: V1_UPDATE_MY_PLAYER_PICKS, player_picks: [resp.data.player_pick], offset:1 })
        } catch (e) {
            console.log(e)
            errHandler(e)
        }
    }
}


export const comps_joinCompetition = (competition_player:CompetitionPlayerProps) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.post(`${TP_SVC_API}/v1/competitions/player/join`, { competition_player })
            dispatch({ type:V1_UPDATE_COMPETITIONS, competitions: [resp.data.competition] })
            dispatch({ type:V1_UPDATE_COMPETITION_PLAYERS, competition_players: [resp.data.competition_player] })
        } catch (e) {
            console.log(e)
            errHandler(e)
        }
    }
}

export const comps_leaveCompetition = (competition_player:CompetitionPlayerProps) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.post(`${TP_SVC_API}/v1/competitions/player/leave`, { competition_player })
            dispatch({ type:V1_UPDATE_COMPETITIONS, competitions: [resp.data.competition] })
            dispatch({ type:V1_UPDATE_COMPETITION_PLAYERS, competition_players: [resp.data.competition_player] })
        } catch (e) {
            console.log(e)
            errHandler(e)
        }
    }
}


//PLAYER BRACKETS
export const comps_createBracketCompetition = (bracket_competition:BracketCompetitionProps, bracket_competition_scoring_rules:BracketCompetitionScoringRuleProps[], share?:ShareProps) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.post(`${TP_SVC_API}/v1/brackets/competition/create`, { bracket_competition, bracket_competition_scoring_rules, share })
            dispatch({ type: V1_UPDATE_BRACKET_COMPETITIONS, bracket_competitions:[resp.data.bracket_competition] })
        } catch (e) {
            console.log(e)
            errHandler(e)
        }
    }
}


export const comps_deleteBracketCompetition = (bracket_competition_id:string) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.post(`${TP_SVC_API}/v1/brackets/competition/delete`, { bracket_competition_id })
            dispatch({ type: V1_UPDATE_BRACKET_COMPETITIONS, bracket_competitions:[resp.data.bracket_competition] })
            dispatch({ type: V1_LOAD_ALERT, alert: { title:'Success', type:'success', body:'Successfully deleted competition' } })
        } catch (e) {
            console.log(e)
            errHandler(e)
        }
    }
}


export const comps_shareBracketCompetition = async(bracket_competition_id:string, share:ShareProps) => {
    try {
        await axios.post(`${TP_SVC_API}/v1/brackets/competition/share`, { bracket_competition_id, share })
    } catch (e) {
        console.log(e)
    }
}

export const comps_shareCompetition = async(competition_id:string, share:ShareProps) => {
    try {
        await axios.post(`${TP_SVC_API}/v1/competitions/competition/share`, { competition_id, share })
    } catch (e) {
        console.log(e)
    }
}


export const comps_getBracketCompetitions = (status?:string) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.get(`${TP_SVC_API}/v1/brackets/competitions/all?status=${status}`)
            dispatch({ type: V1_UPDATE_BRACKET_COMPETITIONS, bracket_competitions:resp.data.bracket_competitions })
        } catch (e) {
            console.log(e)
        }
    }
}

export const comps_getPlayerBrackets = (anonymous_id?:string, bracket_id?:string) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.get(`${TP_SVC_API}/v1/brackets/player?bracket_id=${bracket_id}`)
            dispatch({ type:V1_LOAD_PLAYER_BRACKETS, player_brackets: resp.data.player_brackets })
        } catch (e) {
            console.log(e)
        }
    }
}

export const comps_createPlayerBracket = (player_bracket:PlayerBracketProps) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.post(`${TP_SVC_API}/v1/brackets/bracket/create`, { player_bracket })
            dispatch({ type: V1_LOAD_PLAYER_BRACKETS, player_brackets: [resp.data.player_bracket] })
        } catch (e) {
            console.log(e)
        }
    }
}

export const comps_updatePlayerBracket = (player_bracket:PlayerBracketProps) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.post(`${TP_SVC_API}/v1/brackets/bracket/update`, { player_bracket })
            dispatch({ type: V1_LOAD_PLAYER_BRACKETS, player_brackets: [resp.data.player_bracket] })
        } catch (e) {
            errHandler(e)
            console.log(e)
        }
    }
}

export const comps_joinBracketCompetition = (competition_player_bracket:CompetitionPlayerBracketProps) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.post(`${TP_SVC_API}/v1/brackets/competition/join`, { competition_player_bracket })
            dispatch({ type:V1_UPDATE_COMPETITION_PLAYER_BRACKETS, competition_player_brackets:[resp.data.competition_player_bracket] })
        } catch (e) {
            console.log(e)
            errHandler(e)
        }
    }
}


export const comps_leaveBracketCompetition = (competition_player_bracket_id:string) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.post(`${TP_SVC_API}/v1/brackets/competition/leave`, { competition_player_bracket_id })
            dispatch({ type:V1_UPDATE_COMPETITION_PLAYER_BRACKETS, competition_player_brackets:[resp.data.competition_player_bracket] })
        } catch (e) {
            console.log(e)
            errHandler(e)
        }
    }
}


export const comps_getCompetitionPlayerBrackets = (bracket_competition_id:string, offset:number, player_bracket_id?:string) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.get(`${TP_SVC_API}/v1/brackets/competitionplayer/competition/${bracket_competition_id}?offset=${offset}&player_bracket_id=${player_bracket_id}`)
            dispatch({ type:V1_UPDATE_COMPETITION_PLAYER_BRACKETS, competition_player_brackets:resp.data.competition_player_brackets })
        } catch (e) {
            console.log(e)
        }
    }
}

export const comps_getCompetitionPlayerBracketById = (competition_player_bracket_id:string) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.get(`${TP_SVC_API}/v1/brackets/competitionplayer/id/${competition_player_bracket_id}`)
            dispatch({ type:V1_UPDATE_COMPETITION_PLAYER_BRACKETS, competition_player_brackets: [resp.data.competition_player_bracket] })
            dispatch({ type: V1_LOAD_PLAYER_BRACKETS, player_brackets: [resp.data.player_bracket] })
        } catch (e) {
            console.log('getting bracket by id')
            console.log(e)
        }
    }
}

export const comps_getBracketCompetitionById = async(bracket_compeition_id:string):Promise<BracketCompetitionProps | undefined> => {
    try {
        const resp = await axios.get(`${TP_SVC_API}/v1/brackets/competition/${bracket_compeition_id}`)
        return resp.data.bracket_competition
    } catch (e) {
        console.log(e)
        return undefined
    }
}

export const comps_getCompetitionPlayerBracketsByPlayerId = (player_id:string, bracket_competition_id?:string, status?:string, history_days?:number) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.get(`${TP_SVC_API}/v1/brackets/competitionplayer/player/${player_id}?bracket_competition_id?=${bracket_competition_id}&status=${status}&history_days=${history_days}`)
            dispatch({ type: V1_UPDATE_BRACKET_COMPETITIONS, bracket_competitions:resp.data.bracket_competitions })
            dispatch({ type: V1_UPDATE_COMPETITION_PLAYER_BRACKETS, competition_player_brackets:resp.data.competition_player_brackets })
        } catch (e) {
            console.log(e)
        }
    }
}

export const comps_getPlayerBracketById = (player_bracket_id:string) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.get(`${TP_SVC_API}/v1/brackets/bracket/${player_bracket_id}`)
            dispatch({ type: V1_UPDATE_PLAYER_BRACKET_PICKS, player_bracket_picks:resp.data.player_bracket_picks })
        } catch (e) {
            console.log(e)
        }
    }
}

export const comps_savePlayerBracketPick = (player_bracket_pick:PlayerBracketPickProps) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.post(`${TP_SVC_API}/v1/brackets/pick/create`, { player_bracket_pick })
            dispatch({ type: V1_UPDATE_PLAYER_BRACKET_PICKS, player_bracket_picks: resp.data.player_bracket_picks })
            dispatch({ type: V1_LOAD_PLAYER_BRACKETS, player_brackets: [resp.data.player_bracket] })
            dispatch({ type: V1_UPDATE_COMPETITION_PLAYER_BRACKETS, competition_player_brackets: resp.data.competition_player_brackets })
        } catch (e) {
            console.log(e)
        }
    }
}



/*
SQUARES ACTIONS
*/

export const comps_getSquaresCompetitionsByStatus = (status:string) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.get(`${TP_SVC_API}/tp/squares/comp/all?status=${status}`)
            dispatch({ type: V1_LOAD_SQUARES_COMPETITIONS, squares_competitions: resp.data.squares_competitions })
        } catch (e) {
            console.log(e)
        }
    }
}

export type SquareUpdateProps = {
    event_id:string,
    scores: {
        iteration: string|number,
        away_team_score : number,
        home_team_score : number
    }[]
}
export const comps_updateSquareSores = (event_score:SquareUpdateProps) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.post(`${TP_SVC_API}/tp/squares/comp/manualEventUpdate`, { event_score })
        } catch (e) {
            alert('There was an error')
            console.log(e)
        }
    }
}

export const comps_getSquaresCompetitionById = (sq_comp_id:string) => {
    return async(dispatch:any) => {
        try {
            let resp = await axios.get(`${TP_SVC_API}/tp/squares/comp/details/${sq_comp_id}`)
            const { squares, player_squares, squares_type, squares_payout_type, squares_competition, square_results, square_offers, player_square_history } = resp.data;
            dispatch({ type:V1_LOAD_SQUARE_DETAILS, squares, player_squares, squares_type, squares_payout_type, squares_competition, square_results, square_offers, player_square_history })
        } catch (e) {
            console.log(e)
            errHandler(e)
        }
    }
}



export const comps_clearSquaresCompetition = () => {
    return (dispatch:any) => {
        dispatch({ type:V1_LOAD_SQUARE_DETAILS, squares:[], player_squares:[], squares_type:undefined, squares_payout_type:undefined, squares_competition:undefined, square_results:[], square_offers:[], player_square_history:[] })
    }
}

export const comps_buySquares = (sq_comp_id:string, player_squares:PlayerSquareProps[]) => {
    return async() => {
        try {
            await axios.post(`${TP_SVC_API}/tp/squares/square/buy`, { sq_comp_id, player_squares })
        } catch (e) {
            console.log(e)
            errHandler(e)
        }
    }
}

export const comps_offerSquare = (sq_offer:SquareOfferProps) => {
    return async() => {
        try {
            await axios.post(`${TP_SVC_API}/tp/squares/square/offer`, { square_offer:sq_offer })
        } catch (e) {
            console.log(e.message)
            errHandler(e)
        }
    }
}